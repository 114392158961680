import React from 'react';
import { Card, Typography } from 'antd';

const { Title } = Typography;
export const Faq = () => {
  return (
    <Card title="Frequently Asked Questions">
      <Title level={4}>What is CuedUp?</Title>
      <p>
        CuedUp helps you and your streaming audience organize demo submissions
        and feedback for songs!
      </p>
      <p>
        Use it while live streaming on your favorite platform (Twitch, Youtube,
        etc.)
      </p>
      <Title level={4}>
        What benefits does CuedUp provide to the host of the stream?
      </Title>
      <p>As a host, CuedUp allows you to:</p>
      <ul>
        <li>Efficiently go through a list of demos</li>
        <li>Organize the tracks by submission time</li>
        <li>Open and close submissions for your session</li>
      </ul>
      <Title level={4}>
        What benefits does CuedUp provide to the audience of the stream?
      </Title>
      <p>As a viewer, CuedUp allows you to:</p>
      <ul>
        <li>Submit tracks to your favorite artists' feedback sessions</li>
        <li>See the time at which your track was played</li>
        <li>Easily find the url for the streamer's session</li>
      </ul>
      <Title level={4}>
        Which platforms are supported for submitted tracks?
      </Title>
      <p>Currently, only Soundcloud is supported.</p>
    </Card>
  );
};
