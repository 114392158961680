import React, { createContext, useState } from 'react';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { message } from 'antd';
import 'firebase/analytics';
import 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

type FirebaseContext = {
  analytics: firebase.analytics.Analytics;
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  mountSignInUi: (sel: string) => void;
  openSignIn?: OpenSignIn;
  setOpenSignIn: (fn: OpenSignIn) => void;
  user: firebase.User | undefined;
};

export const firebaseContext = createContext({} as FirebaseContext);

const firebaseConfig = {
  apiKey: 'AIzaSyDnB6nLulLKWf38MayqunBGD5Z6vzckP2o',
  authDomain: 'cuedup-b68b0.firebaseapp.com',
  databaseURL: 'https://cuedup-b68b0.firebaseio.com',
  projectId: 'cuedup-b68b0',
  storageBucket: 'cuedup-b68b0.appspot.com',
  messagingSenderId: '882321871110',
  appId: '1:882321871110:web:b3d83081dbd7d682a7ce87',
  measurementId: 'G-S5SL84568E',
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();
const ui = new firebaseui.auth.AuthUI(auth);

const anonymousUser = auth.currentUser;

type OpenSignIn = (open?: boolean) => void;

export const FirebaseProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [user] = useAuthState(auth);
  const [openSignIn, setOpenSignIn] = useState<OpenSignIn>();

  const uiConfig = {
    autoUpgradeAnonymousUsers: true,
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    callbacks: {
      signInSuccessWithAuthResult: function () {
        message.success('Sign in successful!');
        return false;
      },
      signInFailure: async function (error: firebase.auth.AuthError) {
        // For merge conflicts, the error.code will be
        // 'firebaseui/anonymous-upgrade-merge-conflict'.
        if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
          return Promise.resolve();
        }
        // The credential the user tried to sign in with.
        const cred = error.credential;

        // Save anonymous user data first.
        const snapshot = await db
          .collection('sessions')
          .where('userId', '==', auth?.currentUser?.uid)
          .get();

        const { user } = await auth.signInWithCredential(cred!);

        await Promise.all(
          snapshot.docs.map((d) => {
            const sessionRef = db.collection('sessions').doc(d.id);
            return sessionRef.update({
              userId: user?.uid,
              user: {
                displayName: user?.displayName,
                isAnonymous: user?.isAnonymous,
                photoURL: user?.photoURL,
              },
            });
          })
        );

        await anonymousUser?.delete();
      },
    },
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
  };

  const mountSignInUi = (selector: string) => ui?.start(selector, uiConfig);

  return (
    <firebaseContext.Provider
      value={{
        db,
        mountSignInUi,
        user,
        analytics,
        auth,
        openSignIn,
        setOpenSignIn,
      }}
    >
      {props.children}
    </firebaseContext.Provider>
  );
};
