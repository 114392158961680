import * as firebase from 'firebase/app';
import { WithId } from 'types/with-id';

export const withId = <DataType>(
  s?: firebase.firestore.QuerySnapshot
): WithId<DataType>[] | undefined =>
  s?.docs.map((d) => ({
    id: d.id,
    data: d.data() as DataType,
  }));
