import React, { useContext } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useRouteMatch } from 'react-router-dom';

import { firebaseContext } from 'components/firebase-provider';
import { DocumentLoader } from 'components/document-loader';
import { Session } from 'components/session';
import { Session as SessionType } from 'types/session';

export const SessionContainer = () => {
  const { db } = useContext(firebaseContext);

  const {
    params: { id },
  } = useRouteMatch();

  const sessionRef = db.collection('sessions').doc(id);

  const sessionDocument = useDocument(sessionRef, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return (
    <>
      <DocumentLoader status={sessionDocument}>
        {(session: SessionType) => (
          <Session session={session} sessionRef={sessionRef} />
        )}
      </DocumentLoader>
    </>
  );
};
