import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from 'app';
import { FirebaseProvider } from 'components/firebase-provider';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <FirebaseProvider>
      <App />
    </FirebaseProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
