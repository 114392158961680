import React from 'react';

import { Avatar as AntAvatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import * as firebase from 'firebase/app';

import { User } from 'types/user';

type Props = {
  user?: User | firebase.User;
  size?: 'small';
};

export const Avatar = ({ user, size }: Props) => {
  if (user && !user.isAnonymous) {
    return (
      <AntAvatar size={size} style={{ backgroundColor: '#1890ff' }}>
        {user?.displayName?.[0]}
      </AntAvatar>
    );
  }
  return <AntAvatar icon={<UserOutlined />} size={size} />;
};
