import React, { useMemo, useState } from 'react';
import { message, Modal, Table } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { useDocument } from 'react-firebase-hooks/firestore';

import { EmbedSoundcloudInteractive } from 'components/embed-soundcloud-interactive';
import { useFilterColumn } from 'hooks/use-filter-column';
import { useContextMenu } from 'hooks/use-context-menu';
import { Session } from 'types/session';
import { Track } from 'types/track';
import { Secret } from 'types/secret';
import { WithId } from 'types/with-id';

const { confirm } = Modal;

type Props = {
  isHost: boolean;
  session: Session;
  sessionRef: firebase.firestore.DocumentReference;
  tracksWithId: WithId<Track>[];
};

export const TrackView = ({
  isHost,
  session,
  sessionRef,
  tracksWithId,
}: Props) => {
  const [selectedTrack, setSelectedTrack] = useState<WithId<Track>>();
  const activeTrack = useMemo(() => selectedTrack || tracksWithId[0], [
    selectedTrack,
    tracksWithId,
  ]);
  const [showContextMenu, contextMenu] = useContextMenu();

  // Attempt to fetch the secret for this track (will fail if not host or submitter)
  const [secret] = useDocument(
    activeTrack
      ? sessionRef.collection('secrets').doc(activeTrack.id)
      : undefined
  );

  const deleteTrack = async (trackWithId: WithId<Track>) => {
    try {
      const updatePlayedCount = trackWithId.data.played;

      await sessionRef.collection('tracks').doc(trackWithId.id).delete();
      await sessionRef.update({
        trackCount: Math.max((session.trackCount || 0) - 1, 0),
      });
      if (updatePlayedCount) {
        await sessionRef.update({
          playedTrackCount: Math.max((session.playedTrackCount || 0) - 1, 0),
        });
      }
      message.success('Track deleted!');
    } catch (error) {
      message.error('Track could not be deleted. Please try again');
      console.log(error);
    }
  };

  const artistFilterColumn = useFilterColumn(
    (record: any) => record.data.artist || ''
  );
  const titleFilterColumn = useFilterColumn(
    (record: any) => record.data.title || ''
  );

  const columns = [
    {
      title: 'Artist',
      dataIndex: ['data', 'artist'],
      sorter: (a: WithId<Track>, b: WithId<Track>) =>
        a.data.artist?.localeCompare(b.data.artist),
      ...artistFilterColumn,
    },
    {
      title: 'Title',
      dataIndex: ['data', 'title'],
      sorter: (a: WithId<Track>, b: WithId<Track>) =>
        a.data.title?.localeCompare(b.data.title),
      ...titleFilterColumn,
    },
    {
      title: 'Submitted At',
      dataIndex: ['data', 'submittedAt'],
      defaultSortOrder: 'ascend' as any,
      sorter: dateComparator('submittedAt'),
      render: dateRenderer,
    },
    {
      title: 'Played',
      dataIndex: ['data', 'played'],
      sorter: (a: WithId<Track>, b: WithId<Track>) => (a.data.played ? 1 : -1),
      render: booleanRenderer,
    },
    {
      title: 'Played At',
      dataIndex: ['data', 'playedAt'],
      sorter: dateComparator('playedAt'),
      render: dateRenderer,
    },
  ];

  return (
    <>
      {activeTrack ? (
        <div style={{ marginBottom: '1rem' }}>
          <EmbedSoundcloudInteractive
            isHost={isHost}
            session={session}
            sessionRef={sessionRef}
            trackWithId={activeTrack}
            secret={secret?.data() as Secret}
          />
        </div>
      ) : (
        <div style={{ margin: 48, textAlign: 'center' }}>
          <PlayCircleOutlined style={{ marginRight: 12 }} />
          Once the first track is submitted you'll be able to play it here
        </div>
      )}
      <Table
        columns={columns}
        dataSource={tracksWithId}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: activeTrack ? [activeTrack.id] : [],
          type: 'radio',
        }}
        onRow={(track) => ({
          onClick: () => {
            setSelectedTrack(track);
          },
          onContextMenu: (event) =>
            isHost &&
            showContextMenu(
              event,
              <>
                <li
                  key="1"
                  onClick={() =>
                    confirm({
                      title: `Are you sure you want to delete ${track.data.title}?`,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        deleteTrack(track);
                      },
                    })
                  }
                >
                  <DeleteOutlined />
                  <span style={{ marginLeft: 8 }}>Delete track</span>
                </li>
              </>
            ),
        })}
      />
      {contextMenu}
    </>
  );
};

const dateRenderer = (timestamp?: firebase.firestore.Timestamp) => {
  return timestamp?.toDate().toLocaleString();
};

const booleanRenderer = (value?: boolean) => {
  return value ? <CheckOutlined /> : <div />;
};

const dateComparator = (key: keyof Track) => (
  a: WithId<Track>,
  b: WithId<Track>
) =>
  ensureDate(a.data[key] as firebase.firestore.Timestamp | undefined) >
  ensureDate(b.data[key] as firebase.firestore.Timestamp | undefined)
    ? 1
    : -1;

const ensureDate = (date?: firebase.firestore.Timestamp) =>
  date ? date.toDate() : new Date();
