import React, { useCallback, useContext } from 'react';
import { Card, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import * as firebase from 'firebase/app';

import { firebaseContext } from 'components/firebase-provider';
import { Track } from 'types/track';
import { WithId } from 'types/with-id';
import { EmbedSoundcloud } from 'components/embed-soundcloud';
import { buildTrackUrl } from 'utils/url';
import { Session } from 'types/session';
import { Secret } from 'types/secret';

type Props = {
  isHost: boolean;
  secret?: Secret;
  session: Session;
  sessionRef: firebase.firestore.DocumentReference;
  trackWithId: WithId<Track>;
};

export const EmbedSoundcloudInteractive = ({
  isHost,
  secret,
  session,
  sessionRef,
  trackWithId,
}: Props) => {
  const { id } = trackWithId;

  const { analytics } = useContext(firebaseContext);

  const markAsPlayed = useCallback(
    async (e: any) => {
      if (e.currentPosition || !isHost) {
        return;
      }

      try {
        const updatePlayedCount = !trackWithId.data.played;
        await sessionRef
          .collection('tracks')
          .doc(id)
          .update({
            played: true,
            playedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          });

        if (updatePlayedCount) {
          await sessionRef.update({
            playedTrackCount: (session.playedTrackCount || 0) + 1,
          });
        }

        message.success('Track marked as played');
        analytics.logEvent('track_played');
      } catch (e) {
        console.log('Marking as Played Failed:', e);
        message.error('Track could not be marked as played. Please try again');
      }
    },
    [id] // Purposefully omitting deps so that the the widget doesn't get multiple bindings
    // TODO: this should be handled by embed-soundcloud
  );

  if (trackWithId.data.isPrivate && !secret) {
    return (
      <Card>
        <LockOutlined />
        <span style={{ marginLeft: 8 }}>
          This track is private and can only be played by the host or the
          submitter
        </span>
      </Card>
    );
  }

  return (
    <>
      <EmbedSoundcloud
        url={buildTrackUrl(trackWithId.data.url, secret?.secretToken)}
        onPlay={markAsPlayed}
      />
      {trackWithId.data.isPrivate && secret && (
        <Card>
          <LockOutlined />
          <span style={{ marginLeft: 8, marginRight: 8 }}>
            This track is private.
          </span>
          <a href={`${trackWithId.data.url}/${secret?.secretToken}`}>
            Go to the track
          </a>
        </Card>
      )}
    </>
  );
};
