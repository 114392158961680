import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Input, message } from 'antd';
import * as firebase from 'firebase/app';

import { firebaseContext } from 'components/firebase-provider';

type Props = {
  isBug?: boolean;
};

export const FeedbackForm = ({ isBug }: Props) => {
  const { db, user } = useContext(firebaseContext);
  const [captchaComplete, setCaptchaComplete] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [recaptcha, setRecaptcha] = useState<firebase.auth.RecaptchaVerifier>();

  useEffect(() => {
    const t = setTimeout(() => {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha',
        {
          callback: () => {
            setCaptchaComplete(true);
          },
        }
      );
      recaptchaVerifier.render();
      setRecaptcha(recaptchaVerifier);
    }, 500);
    return () => clearTimeout(t);
  }, []);

  const onFinish = async (values: any) => {
    try {
      await db.collection('feedback').add({
        description: values.description,
        isBug: !!isBug,
        submittedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        ...(user ? { userId: user.uid } : {}),
      });
      message.success(
        `Your ${isBug ? 'bug report' : 'feedback'} has been received`
      );
      form.resetFields();
      recaptcha?.clear();
      setCaptchaComplete(false);
    } catch (e) {
      message.error(
        `Your ${
          isBug ? 'bug report' : 'feedback'
        } could not be submitted. Please try again`
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    message.error(
      `Your ${
        isBug ? 'bug report' : 'feedback'
      } could not be submitted. Please try again`
    );
  };

  return (
    <Card
      title={
        isBug
          ? 'See something wacky? Report a bug'
          : 'Leave feedback or request a feature'
      }
    >
      <Form
        form={form}
        name="feedback-form"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{
          span: 3,
        }}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please input a description',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 3,
            span: 16,
          }}
        >
          <div id="recaptcha" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 3,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" disabled={!captchaComplete}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
