import React from 'react';
import * as firebase from 'firebase';
import { Result, Spin } from 'antd';

export type Snapshot =
  | firebase.firestore.DocumentSnapshot
  | firebase.firestore.QuerySnapshot
  | undefined;

export type LoaderProps = {
  status: [Snapshot, boolean, Error | undefined];
  children: (value: any) => JSX.Element | JSX.Element[];
};

type Props<ResultType> = LoaderProps & {
  handleResult: (s: Snapshot) => ResultType;
  validateResult: (s: Snapshot) => boolean;
};

export function Loader<ResultType>({
  children,
  handleResult,
  status: [value, loading, error],
  validateResult,
}: Props<ResultType>) {
  if (loading) {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Spin style={{ margin: 'auto' }} />
      </div>
    );
  }

  if (error || !validateResult(value)) {
    error && console.log(error);
    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try again"
      />
    );
  }

  return <>{children(handleResult(value))}</>;
}
