import React from 'react';
import * as firebase from 'firebase/app';

import { Loader, LoaderProps, Snapshot } from 'components/loader';

export const DocumentLoader = (props: LoaderProps) => (
  <Loader
    handleResult={(s: Snapshot) =>
      (s as firebase.firestore.DocumentSnapshot).data()
    }
    validateResult={(s: Snapshot) =>
      !!(s as firebase.firestore.DocumentSnapshot)?.data()
    }
    {...props}
  />
);
