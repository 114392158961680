import React from 'react';
import * as firebase from 'firebase/app';

import { Loader, LoaderProps, Snapshot } from 'components/loader';
import { withId } from 'utils/collection';

export const CollectionLoader = (props: LoaderProps) => (
  <Loader
    handleResult={(s: Snapshot) =>
      withId(s as firebase.firestore.QuerySnapshot)
    }
    validateResult={(s: Snapshot) =>
      !!(s as firebase.firestore.QuerySnapshot)?.docs
    }
    {...props}
  />
);
