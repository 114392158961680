import React, { useContext, useMemo, useState } from 'react';
import { Button, Card, Popover, message } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useClipboard } from 'use-clipboard-copy';

import { Avatar } from 'components/avatar';
import { firebaseContext } from 'components/firebase-provider';
import { CollectionLoader } from 'components/collection-loader';
import { EditableText } from 'components/editable-text';
import { SubmitTrackModal } from 'components/submit-track-modal';
import { SubmissionStatus } from 'components/submission-status';
import { TrackView } from 'components/track-view';
import { Session as SessionType } from 'types/session';

type Props = {
  session: SessionType;
  sessionRef: firebase.firestore.DocumentReference;
};

export const Session = ({ session, sessionRef }: Props) => {
  const { user, openSignIn } = useContext(firebaseContext);

  const isHost = useMemo(() => user?.uid === session.userId, [user, session]);

  const tracksCollection = useCollection(sessionRef.collection('tracks'), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [submitTrackModalVisible, setSubmitTrackModalVisible] =
    useState<boolean>(false);

  const clipboard = useClipboard({
    onSuccess() {
      message.success('Link copied!');
    },
    onError() {
      message.error('Failed to copy. Please try again');
    },
  });

  const link = window.location.hostname + window.location.pathname;

  const saveName = async (newName: string) => {
    try {
      await sessionRef.update({
        name: newName,
      });
      message.success('Session name saved!');
    } catch (e) {
      console.log('Name save failed:', e);
      message.error('Session name could not be saved. Please try again');
    }
  };

  return (
    <CollectionLoader status={tracksCollection}>
      {(tracksWithId) => (
        <Card
          title={
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 12,
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <EditableText
                    allowEdit={isHost}
                    value={session.name}
                    onSave={saveName}
                  />
                  <div style={{ marginLeft: 12, fontSize: 14 }}>
                    ({session.playedTrackCount}/{tracksWithId.length} tracks
                    played)
                  </div>
                </div>
                <Popover
                  content="Click to copy this link (share this session)"
                  trigger="hover"
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LinkOutlined style={{ marginRight: 12 }} />
                    <div onClick={() => clipboard.copy(link)}>{link}</div>
                  </div>
                </Popover>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar size="small" user={session.user} />

                  <div
                    style={{ fontSize: 14, marginLeft: 12, marginRight: 12 }}
                  >
                    {session.user.displayName || 'Anonymous'} is hosting
                  </div>
                  {isHost && session.user.isAnonymous && (
                    <Button onClick={() => openSignIn?.(true)}>
                      Sign in or register to save this session
                    </Button>
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SubmissionStatus
                    isHost={isHost}
                    session={session}
                    sessionRef={sessionRef}
                  />
                  <Button
                    type="primary"
                    onClick={() => setSubmitTrackModalVisible(true)}
                    disabled={!session.submissionsOpen}
                  >
                    Submit a track
                  </Button>
                </div>
              </div>
            </div>
          }
        >
          <TrackView
            isHost={isHost}
            tracksWithId={tracksWithId}
            session={session}
            sessionRef={sessionRef}
          />
          <SubmitTrackModal
            close={() => setSubmitTrackModalVisible(false)}
            session={session}
            sessionRef={sessionRef}
            visible={submitTrackModalVisible}
          />
        </Card>
      )}
    </CollectionLoader>
  );
};
