import React, { useState, useEffect, useRef } from 'react';

import { usePrevious } from 'hooks/use-previous';

type Props = {
  url: string;
  onPlay?: (e: any) => void;
  onReady?: (widget: any) => void;
};

export const EmbedSoundcloud = ({ onReady, onPlay, url }: Props) => {
  const iframeRef = useRef<any>();
  const [widget, setWidget] = useState<any>();

  const previousUrl = usePrevious(url);

  useEffect(() => {
    if (iframeRef.current) {
      const widget = SC.Widget(iframeRef.current);
      setWidget(widget);
    }
  }, [iframeRef]);

  // Toggle widget playing when enter is pressed
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === 'Space' && !e.target?.hasOwnProperty('value')) {
        widget.toggle();
      }
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [widget]);

  useEffect(() => {
    widget?.bind(SC.Widget.Events.READY, () => onReady?.(widget));
    widget?.bind(SC.Widget.Events.PLAY, onPlay);
    return () => {
      widget?.unbind(SC.Widget.Events.READY);
      widget?.unbind(SC.Widget.Events.PLAY);
    };
  }, [widget, onPlay, onReady]);

  useEffect(() => {
    if (widget && previousUrl !== url) {
      widget?.load(url);
    }
  }, [url, previousUrl, widget]);

  return (
    <iframe
      ref={iframeRef}
      title={url}
      width="100%"
      height="166"
      scrolling="no"
      frameBorder="no"
      src={`https://w.soundcloud.com/player/?url=${url}`}
    ></iframe>
  );
};
