import React, { useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

export const useFilterColumn = <RecordType, _>(
  valueGetter: (record: RecordType) => string
) => {
  const inputRef = useRef<any>();
  const [searchText, setSearchText] = useState<string>();

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      clearFilters,
      confirm,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          onPressEnter={() => {
            setSearchText(selectedKeys);
            confirm();
          }}
          placeholder={'Filter'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => {
            setSearchText(selectedKeys);
            confirm();
          }}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setSearchText('');
            clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: RecordType) =>
      valueGetter(record).toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) =>
      visible && setTimeout(() => inputRef.current.select()),
    render: (text: string) =>
      searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText.toString()]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  };
};
