import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

type Props = {
  allowEdit: boolean;
  value: string;
  onSave: (s: string) => Promise<void>;
};

export const EditableText = ({ allowEdit, value, onSave }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef<any>();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditing]);

  const save = async () => {
    try {
      await onSave(inputRef.current.input.value);
      setIsEditing(false);
    } catch (e) {
      console.log('Editable text not saved:', e);
    }
  };

  return (
    <div>
      {isEditing ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input ref={inputRef} defaultValue={value} onPressEnter={save} />
          <div>
            <SaveOutlined
              style={{ color: 'green', marginLeft: 12 }}
              onClick={save}
            />
          </div>
        </div>
      ) : (
        <>
          <span>{value}</span>
          {allowEdit && (
            <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => setIsEditing(true)}
            />
          )}
        </>
      )}
    </div>
  );
};
