import React from 'react';
import { Button, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';

import imgCompose from 'assets/undraw_compose.svg';
import imgFeedback from 'assets/undraw_audio_conversation.svg';
import logo from 'assets/logo.png';
import mockup from 'assets/app_mock.png';
import { useCreateSession } from 'hooks/use-create-session';
import { ArrowRightOutlined, PlayCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const Home = () => {
  const { createSession, isSessionLoading } = useCreateSession();

  return (
    <div
      style={{
        background: 'linear-gradient(-10deg, white 65%, #1890ff 0%)',
        padding: 24,
      }}
    >
      <div>
        <div
          style={{
            marginBottom: 24,
            paddingBottom: 24,
            borderBottom: '1px solid light-gray',
            textAlign: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '24px 0',
              }}
            >
              {/* <img
                alt="logo"
                src={logo}
                style={{
                  height: 50,
                  marginRight: 12,
                  filter: 'drop-shadow(0px 0px 10px white)',
                }}
              /> */}
              <Title style={{ color: 'white', marginTop: 12 }}>
                <PlayCircleOutlined style={{ marginRight: 8 }} />
                <span>CuedUp</span>
              </Title>
            </div>
          </div>
          <Title
            level={3}
            style={{
              color: 'white',
              marginBottom: 36,
              fontWeight: 100,
              letterSpacing: 2,
            }}
          >
            Share demo feedback with your streaming audience
          </Title>
          <div style={{ marginBottom: 24 }}>
            <Button
              onClick={createSession}
              loading={isSessionLoading}
              size="large"
              style={{
                width: 250,
                height: 50,
              }}
            >
              <span>Host a Session </span>
              <ArrowRightOutlined />
            </Button>
          </div>
          <img
            src={mockup}
            alt="mockup"
            style={{ width: '100%', maxWidth: 800, margin: 'auto' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            maxWidth: 1200,
            margin: 'auto',
          }}
        >
          <div className="non-mobile">
            <div style={{ margin: 24, maxWidth: 400 }}>
              <img src={imgCompose} alt="Share demo feeback" width="100%" />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              maxWidth: 400,
            }}
          >
            <Card>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 12 }}>
                  <p>
                    Host a feedback session on CuedUp to keep submitted tracks
                    organized and let your audience know when their track has
                    received feedback.
                  </p>
                  <p>CuedUp is 100% free for hosts & viewers!</p>
                </div>
                <Button
                  type="primary"
                  onClick={createSession}
                  loading={isSessionLoading}
                >
                  Host a Session
                </Button>
              </div>
            </Card>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            maxWidth: 1200,
            margin: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              maxWidth: 400,
            }}
          >
            <Card>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 12 }}>
                  Submit tracks to a session on CuedUp and know when your
                  favorite artists have listened.
                </div>
                <Button type="primary">
                  <Link to="/faq">Learn More</Link>
                </Button>
              </div>
            </Card>
          </div>

          <div className="non-mobile">
            <div style={{ margin: 24, maxWidth: 400 }}>
              <img src={imgFeedback} alt="Receive demo feeback" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
