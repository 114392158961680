import { useContext, useState } from 'react';
import { message } from 'antd';
import * as firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';

import { firebaseContext } from 'components/firebase-provider';
import hri from 'human-readable-ids';

export const useCreateSession = () => {
  const { analytics, auth, db, user } = useContext(firebaseContext);
  const history = useHistory();

  const [isSessionLoading, setIsSessionLoading] = useState(false);

  const generateUniqueId = async (
    tries = 1
  ): Promise<{ id: string; tries: number }> => {
    // TODO: analytics

    const id = hri.hri.random();
    const { exists } = await db.collection('sessions').doc(id).get();
    if (!exists) {
      return { id, tries };
    }

    return await generateUniqueId(tries + 1);
  };

  const createSession = async () => {
    const ensuredUser = user || (await auth.signInAnonymously()).user!;
    const { id, tries } = await generateUniqueId();

    await db
      .collection('sessions')
      .doc(id)
      .set({
        name: 'Feedback Session',
        submissionsOpen: true,
        trackCount: 0,
        playedTrackCount: 0,
        userId: ensuredUser.uid,
        user: {
          displayName: ensuredUser.displayName,
          isAnonymous: ensuredUser.isAnonymous,
          photoURL: ensuredUser.photoURL,
        },
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      });

    analytics.logEvent('session_created', { tries });

    return id;
  };

  const tryCreateSession = async () => {
    setIsSessionLoading(true);
    try {
      const id = await createSession();
      message.success('New session created!');
      history.push(`/${id}`);
    } catch (e) {
      message.error('Failed to create new session. Please try again');
      console.log('Failed to create new session:', e);
    }
    setIsSessionLoading(false);
  };

  return { createSession: tryCreateSession, isSessionLoading };
};
