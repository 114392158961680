import React, { useContext, useEffect } from 'react';
import { firebaseContext } from 'components/firebase-provider';
import 'firebaseui/dist/firebaseui.css';

export const SignInForm = () => {
  const { mountSignInUi } = useContext(firebaseContext);
  useEffect(() => {
    mountSignInUi('#sign-in-form');
  }, [mountSignInUi]);

  return <div id="sign-in-form"></div>;
};
