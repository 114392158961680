import React from 'react';
import { Select, message } from 'antd';

import { Session } from 'types/session';

const { Option } = Select;

type Props = {
  isHost: boolean;
  session: Session;
  sessionRef: firebase.firestore.DocumentReference;
};

export const SubmissionStatus = ({ isHost, session, sessionRef }: Props) => {
  const status = session.submissionsOpen ? 'open' : 'closed';

  const onSubmissionStatusChanged = async (option: string) => {
    const statusAction = option === 'open' ? 'opened' : 'closed';

    try {
      await sessionRef.update({
        submissionsOpen: option === 'open' ? true : false,
      });
      message.success(`Submissions ${statusAction}!`);
    } catch (e) {
      console.log('Changing submission status failed:', e);
      message.error(
        `Submissions could not be ${statusAction}. Please try again`
      );
    }
  };

  return isHost ? (
    <div style={{ marginRight: 12 }}>
      <span style={{ fontSize: 14 }}>Submissions are</span>
      <Select
        defaultValue={status}
        style={{ width: 90 }}
        bordered={false}
        onChange={onSubmissionStatusChanged}
      >
        <Option value="open">open</Option>
        <Option value="closed">closed</Option>
      </Select>
    </div>
  ) : (
    <div style={{ fontSize: 14, marginRight: 12 }}>
      Submissions are {status}
    </div>
  );
};
