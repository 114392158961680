import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, message } from 'antd';
import 'antd/dist/antd.css';
import {
  BugOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  NotificationOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';
import 'sc-widget';

import { Avatar } from 'components/avatar';
import { firebaseContext } from 'components/firebase-provider';
import { Home } from 'components/home';
import { SessionContainer } from 'components/session-container';
import { SignInForm } from 'components/sign-in-form';
import { Sessions } from 'components/sessions';
import { FeedbackForm } from 'components/feedback-form';
import { Faq } from 'components/faq';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export const App = () => {
  const { analytics, auth, user, openSignIn, setOpenSignIn } = useContext(
    firebaseContext
  );
  const [menuOpenKeys, setMenuOpenKeys] = useState<string[]>([]);
  const routeMatch = useRouteMatch();
  useEffect(() => {
    if (!openSignIn) {
      setOpenSignIn(() => (open?: boolean) =>
        setMenuOpenKeys(open ? ['user'] : [])
      );
    }
  }, [openSignIn, setOpenSignIn]);

  useEffect(() => {
    analytics.logEvent('page_view', { path: routeMatch.path });
  }, [routeMatch.path, analytics]);

  const signOut = async () => {
    await auth.signOut();
    message.success('Log out successful!');
  };

  useEffect(() => openSignIn?.(false), [user, openSignIn]);

  return (
    <Layout style={{ height: '100%' }}>
      <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Link to="/">
            <div style={{ color: 'white', marginRight: 48 }}>
              <PlayCircleOutlined style={{ marginRight: 12 }} />
              CuedUp
            </div>
          </Link>
        </div>
        <Menu
          mode="horizontal"
          style={{ background: 'none' }}
          openKeys={menuOpenKeys}
          onOpenChange={(keys: string[]) => setMenuOpenKeys(keys)}
        >
          <SubMenu key="user" title={<Avatar user={user} />}>
            {user && !user.isAnonymous ? (
              <Menu.Item>
                <div onClick={signOut}>
                  <LogoutOutlined />
                  Log out
                </div>
              </Menu.Item>
            ) : (
              <SignInForm />
            )}
          </SubMenu>
        </Menu>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={[]}
            defaultOpenKeys={['user', 'help']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <SubMenu
              key="user"
              title={
                <span>
                  <Avatar user={user} size="small" />
                  <span style={{ marginLeft: 12 }}>
                    {user && !user.isAnonymous ? user.displayName : 'Anonymous'}
                  </span>
                </span>
              }
            >
              {!user || user.isAnonymous ? (
                <Menu.Item key="register" onClick={() => openSignIn?.(true)}>
                  <LoginOutlined />
                  Sign in or register
                </Menu.Item>
              ) : (
                <Menu.Item key="sessions">
                  <Link to="/sessions">
                    <UnorderedListOutlined /> Sessions
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="help"
              title={
                <span>
                  <NotificationOutlined />
                  Help
                </span>
              }
            >
              <Menu.Item key="faq">
                <Link to="/faq">
                  <QuestionCircleOutlined />
                  FAQ
                </Link>
              </Menu.Item>
              <Menu.Item key="feedback">
                <Link to="/feedback">
                  <MessageOutlined />
                  Leave feedback
                </Link>
              </Menu.Item>
              <Menu.Item key="bug">
                <Link to="/bug">
                  <BugOutlined />
                  Report a bug
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout style={{ padding: 48 }} className="no-padding-mobile">
          <Content
            className="site-layout-background"
            id="content"
            style={{
              backgroundColor: 'white',
              padding: 0,
              margin: 0,
              minHeight: 280,
              flex: 'initial',
            }}
          >
            <Switch>
              <Route path="/sessions">
                {user ? <Sessions user={user} /> : <Home />}
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/bug">
                <FeedbackForm isBug />
              </Route>
              <Route path="/feedback">
                <FeedbackForm />
              </Route>
              <Route path="/:id">
                <SessionContainer />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
