import React, { useContext } from 'react';
import { message, Button, Card, List, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { CollectionLoader } from 'components/collection-loader';
import { firebaseContext } from 'components/firebase-provider';
import { useCreateSession } from 'hooks/use-create-session';
import { useContextMenu } from 'hooks/use-context-menu';
import { Session } from 'types/session';
import { WithId } from 'types/with-id';

const { confirm } = Modal;

type Props = {
  user: firebase.User;
};

export const Sessions = (props: Props) => {
  const { user } = props;
  const { db } = useContext(firebaseContext);
  const { createSession, isSessionLoading } = useCreateSession();
  const [showContextMenu, contextMenu] = useContextMenu();

  const sessionsCollection = useCollection(
    db.collection('sessions').where('userId', '==', user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const deleteSession = async (sessionWithId: WithId<Session>) => {
    try {
      // Delete tracks
      const tracks = await db
        .collection('sessions')
        .doc(sessionWithId.id)
        .collection('tracks')
        .get();

      tracks.docs.forEach((d) => d.ref.delete());

      // Delete secrets
      const secrets = await db
        .collection('sessions')
        .doc(sessionWithId.id)
        .collection('secrets')
        .get();

      secrets.docs.forEach((d) => d.ref.delete());

      await db.collection('sessions').doc(sessionWithId.id).delete();
      message.success('Session deleted!');
    } catch (error) {
      message.error('Session could not be deleted. Please try again');
      console.log(error);
    }
  };

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>Your Feedback Sessions</div>
          <Button
            type="primary"
            onClick={createSession}
            loading={isSessionLoading}
          >
            Host a new Session
          </Button>
        </div>
      }
    >
      <CollectionLoader status={sessionsCollection}>
        {(sessionsWithId: WithId<Session>[]) => (
          <List
            itemLayout="horizontal"
            dataSource={sessionsWithId.sort((a, b) => (a > b ? 1 : -1))}
            renderItem={(item) => (
              <List.Item
                onContextMenu={(event) => {
                  showContextMenu(
                    event,
                    <>
                      <li
                        key="1"
                        onClick={() =>
                          confirm({
                            title: `Are you sure you want to delete ${item.data.name}?`,
                            icon: <ExclamationCircleOutlined />,
                            onOk() {
                              deleteSession(item);
                            },
                          })
                        }
                      >
                        <DeleteOutlined />
                        <span style={{ marginLeft: 8 }}>Delete session</span>
                      </li>
                    </>
                  );
                }}
              >
                <List.Item.Meta
                  title={<Link to={`/${item.id}`}>{item.data.name}</Link>}
                  description={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{ flexBasis: '25%' }}
                      >{`${window.location.hostname}/${item.id}`}</div>
                      <div style={{ flexBasis: '25%' }}>
                        {`${item.data.playedTrackCount}/${item.data.trackCount} tracks played`}
                      </div>
                      <div style={{ flexBasis: '25%' }}>
                        {`Submissions are ${
                          item.data.submissionsOpen ? 'open' : 'closed'
                        }`}
                      </div>
                      <div style={{ flexBasis: '25%' }}>
                        {`Created at ${item.data.createdAt
                          ?.toDate()
                          .toLocaleString()}`}
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </CollectionLoader>
      {contextMenu}
    </Card>
  );
};
