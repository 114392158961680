import React, { useState } from 'react';

export const useContextMenu = () => {
  const [coords, setCoords] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [items, setItems] = useState<JSX.Element>();

  const show = (e: React.MouseEvent, items: JSX.Element) => {
    if (!visible) {
      document.addEventListener('click', function onClickOutside() {
        setVisible(false);
        document.removeEventListener('click', onClickOutside);
      });
      setVisible(true);
    }
    setItems(items);
    setCoords({ x: e.clientX, y: e.clientY });
    e.preventDefault();
    return false;
  };

  const menu = visible ? (
    <ul className="popup" style={{ left: coords.x, top: coords.y }}>
      {items}
    </ul>
  ) : (
    <></>
  );

  return [show, menu] as [typeof show, typeof menu];
};
