export type ParsedTrackUrl = {
  url: string;
  secretToken?: string;
};

export const parseTrackUrl = (url: string) => {
  const u = new URL(url);
  const [_, artist, track, secretToken] = u.pathname.split('/');
  const cleanUrl = [u.origin, artist, track].join('/');

  return {
    url: cleanUrl,
    secretToken: secretToken,
  };
};

export const buildTrackUrl = (url: string, secretToken?: string) =>
  secretToken ? `${url}?secret_token=${secretToken}` : url;
