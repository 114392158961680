import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import * as firebase from 'firebase/app';

import { firebaseContext } from 'components/firebase-provider';
import { EmbedSoundcloud } from 'components/embed-soundcloud';
import { Session } from 'types/session';
import soundcloudIcon from 'assets/soundcloud.svg';
import { ParsedTrackUrl, buildTrackUrl, parseTrackUrl } from 'utils/url';

type Props = {
  close: () => void;
  session: Session;
  sessionRef: firebase.firestore.DocumentReference;
  visible: boolean;
};

export const SubmitTrackModal = ({
  close,
  session,
  sessionRef,
  visible,
}: Props) => {
  const { analytics, auth, user } = useContext(firebaseContext);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [captchaComplete, setCaptchaComplete] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<firebase.auth.RecaptchaVerifier>();
  const [parsedTrackUrl, setParsedTrackUrl] = useState<ParsedTrackUrl>();
  const [trackDetails, setTrackDetails] = useState<any>();

  useEffect(() => {
    if (!visible) {
      return;
    }

    const t = setTimeout(() => {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha',
        {
          callback: () => {
            setCaptchaComplete(true);
          },
        }
      );
      recaptchaVerifier.render();
      setRecaptcha(recaptchaVerifier);
    }, 500);
    return () => clearTimeout(t);
  }, [visible]);

  const submitTrack = async () => {
    if (!parsedTrackUrl) {
      throw new Error('Invalid track url.');
    }

    const ensuredUser = user || (await auth.signInAnonymously()).user!;
    const isPrivate = !!parsedTrackUrl.secretToken;
    const duplicates = await sessionRef
      .collection('tracks')
      .where('url', '==', parsedTrackUrl.url)
      .get();

    if (duplicates.size) {
      throw new Error('This track already exists in the session.');
    }

    const track = await sessionRef.collection('tracks').add({
      url: parsedTrackUrl.url,
      isPrivate,
      title: trackDetails.title,
      artist: trackDetails.user.username,
      submittedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      played: false,
      userId: ensuredUser.uid,
      hostId: session.userId,
    });

    if (isPrivate) {
      await sessionRef.collection('secrets').doc(track.id).set({
        secretToken: parsedTrackUrl.secretToken,
        hostId: session.userId,
        userId: ensuredUser.uid,
      });
    }
  };

  const reset = () => {
    form.resetFields();
    recaptcha?.clear();
    setCaptchaComplete(false);
    setParsedTrackUrl(undefined);
    setTrackDetails(undefined);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      try {
        await submitTrack();
        close();
        reset();

        message.success('Track submitted!');
        analytics.logEvent('track_submitted');
      } catch (e) {
        console.log('Submission Failed:', e);
        message.error(
          `Track could not be submitted. ${e.message} Please try again`
        );
      }
    } catch (info) {
      console.log('Validate Failed:', info);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFieldsChange = async (values: any[]) => {
    const url = values.find((v) => (v.name = ['url']))?.value;
    if (url) {
      setParsedTrackUrl(parseTrackUrl(url));
    }
  };

  const onReady = (widget: any) => {
    try {
      widget?.getCurrentSound((sound: any) => {
        setTrackDetails(sound);
      });
    } catch {}
  };

  return (
    <>
      <Modal
        visible={visible}
        title={`Submit a track to ${session.name}`}
        okText="Submit"
        cancelText="Cancel"
        onCancel={() => {
          close();
          reset();
        }}
        onOk={onSubmit}
        footer={[
          <Button key="back" onClick={close}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={submitting}
            onClick={onSubmit}
            disabled={!captchaComplete || !trackDetails}
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          name="submit-track-form"
          form={form}
          initialValues={{}}
          onFieldsChange={handleFieldsChange}
        >
          <div style={{ marginBottom: 6 }}>
            Currently supported platforms:
            <img
              alt="Soundcloud"
              title="Soundcloud"
              src={soundcloudIcon}
              style={{ width: 24, marginLeft: 12, marginRight: 12 }}
            />
          </div>
          <Form.Item label="URL" name="url">
            <Input />
          </Form.Item>

          {parsedTrackUrl && (
            <EmbedSoundcloud
              url={buildTrackUrl(
                parsedTrackUrl.url,
                parsedTrackUrl.secretToken
              )}
              onReady={onReady}
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div id="recaptcha" />
          </div>
        </Form>
      </Modal>
    </>
  );
};
